// Declare the global values used in this Component
declare var angular: angular.IAngularStatic;

import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';

@Component({
    selector: 'cts-quick-send-destination-panel',
    template: require('./cts-quick-send-destination-panel.component.html'),
})
export class CtsQuickSendDestinationPanelComponent implements OnInit {
    // External Inputs
    @Input() destinations: any|null = null;
    @Input() panel: any|null = null;
    @Input() ordertype: any|null = null;
    @Input() existing: any|null = null;
    @Input() invalid: any|null = null;
    @Input() overloaded: any|null = null;
    // External Outputs
    @Output() added = new EventEmitter();
    @Output() canceled = new EventEmitter();

    public availableDestinations: any = [];
    public hiddenDestinations: Number = 0;
    public hasMismatched: Boolean = false;

    constructor() {}

    ngOnInit() {
        this.availableDestinations = this.destinations.filter((cur:any) => {
            return !this.existing.includes(cur.id);
        });
        this.hiddenDestinations = this.destinations.length - this.availableDestinations.length;

        // Filter available destinations to show which ones are not compatible with the order type
        for(let i = 0; i < this.availableDestinations.length; i++) {
            // Unlock all destinations to begin with
            this.availableDestinations[i].locked = false;

            // Lock destination for digital mismatch
            if(this.availableDestinations[i].DestinationType.distributionType !== this.ordertype) {
                this.availableDestinations[i].locked = true;
                this.hasMismatched = true;
            }
        }
    }

    cancel() {
        this.canceled.emit(true);
    }

    add() {
        this.added.emit(this.destinations.filter((destination:any) => destination.selected));
    }
}

angular.module("comcast.directives").directive(
    'ctsQuickSendDestinationPanel',
    downgradeComponent(
        // The inputs and outputs here must match the relevant names of the properties on the
        // "downgraded" component
        {component: CtsQuickSendDestinationPanelComponent, inputs: ['destinations', 'panel', 'ordertype', 'existing', 'invalid', 'overloaded'], outputs: ['added', 'canceled']}));
