// Declare the global values used in this Component
declare var angular: angular.IAngularStatic;

import { Component, Input, Output, EventEmitter } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';

@Component({
    selector: 'cts-multi-upload-progress-panel',
    template: require('./cts-multi-upload-progress-panel.component.html'),
})
export class CtsMultiUploadProgressPanelComponent {
    // External Inputs
    @Input() spots: any|null = null;
    // External Outputs
    @Output() close = new EventEmitter();

    constructor() {}

    closeDialog() {
        this.close.emit(true);
    }

    allDone():Boolean {
        let allComplete = true;
        for(let i = 0; i < this.spots.length; i++) {
            if(!this.spots[i].progress || this.spots[i].progress === null || this.spots[i].progress < 100) {
                allComplete = false;
            }
        }
        return allComplete;
    }
}

angular.module("comcast.directives").directive(
    'ctsMultiUploadProgressPanel',
    downgradeComponent(
        // The inputs and outputs here must match the relevant names of the properties on the
        // "downgraded" component
        {component: CtsMultiUploadProgressPanelComponent, inputs: ['spots', 'panel'], outputs: ['close']}));
