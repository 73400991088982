// Declare the global values used in this Component
declare var angular: angular.IAngularStatic;

import { Component, Input } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';

@Component({
    selector: 'cts-waiting-panel',
    template: require('./cts-waiting-panel.component.html'),
})
export class CtsWaitingPanelComponent {
    // External Inputs
    @Input() title: any|null = null;
    @Input() conditions: any|null = null;

    constructor() {}
}

angular.module("comcast.directives").directive(
    'ctsWaitingPanel',
    downgradeComponent(
        // The inputs and outputs here must match the relevant names of the properties on the
        // "downgraded" component
        {component: CtsWaitingPanelComponent, inputs: ['title', 'conditions'], outputs: []}));
