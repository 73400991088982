// Declare the global values used in this Component
declare var angular: angular.IAngularStatic;

import { Component, ElementRef, AfterContentInit, Renderer2 } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { fromEvent, Observable, Subscription } from "rxjs";
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'expanding-grid-header',
  template: require('./expanding-grid-header.component.html'),
})
export class ExpandingGridHeaderComponent implements AfterContentInit {
    private resizeObservable$: Observable<Event>;
    private resizeSubscription$: Subscription;
    private masterWidth: number;
    private sizeableColumns: HTMLElement[];

    constructor(
        private renderer: Renderer2,
        private element: ElementRef
    ) {
        this.resizeObservable$ = new Observable();
        this.resizeSubscription$ = new Subscription();
        this.masterWidth = 0;
        this.sizeableColumns = [];
    }

    // Handle window size changes
    ngOnInit() {
        this.resizeObservable$ = fromEvent(window, 'resize');
        this.resizeSubscription$ = this.resizeObservable$.pipe(debounceTime(500)).subscribe( (evt) => {
            // Calculate how much space has been added or removed;
            let change = this.element.nativeElement.offsetWidth - this.masterWidth;
            
            // Adjust each column by a portion of the newly available (or unavailable) space
            this.adjustColumns(change / this.sizeableColumns.length);

            // Finally, update the master width to be correct again
            this.masterWidth = this.element.nativeElement.offsetWidth;
        });
    }

    ngOnDestroy() {
        this.resizeSubscription$.unsubscribe();
    }

    // Dear future me (or others):
    // I hate that I have to do this, but 5.5.0 has been a catastrophe and I have been told
    // to fix the grids so that they attempt to take up most of the view before the UI half
    // of the release can go to production. Thankfully, the Prod upgrade to move our apps
    // into AWS is going to buy me a few weeks, but I'm just one man and there are a lot of
    // grids out there. I pray this finds you well, and surrounded by a loving team - for I am not.
    async ngAfterContentInit() {
        // I haven't been able to figure out exactly why, but the initial load of this component
        // when toggling between pages starts off smaller that after a page refresh. Setting this
        // timeout appears to go into the angular lifecycle loop and cause things to happen after
        // all of the page element sizes have been worked out. I will need to investigate this.
        await new Promise( resolve => setTimeout(resolve, 1));

        this.masterWidth = this.element.nativeElement.offsetWidth;
        this.sizeableColumns = this.element.nativeElement.querySelectorAll('.sizable-header');
        
        this.scaleColumns();
    }

    private adjustColumns(amount: number) {
        for(let i = 0; i < this.sizeableColumns.length; i++) {
            this.renderer.setStyle(this.sizeableColumns[i], 'width', (this.sizeableColumns[i].offsetWidth + amount)+'px');
        }
    }

    private scaleColumns() {
        let fixedHeaders = this.element.nativeElement.querySelectorAll(".md-subheader:not(.sizable-header)");
        let staticWidth = 0;

        // First, calculate the width needed for non-expanding columns
        for(let i = 0; i < fixedHeaders.length; i++) {
            staticWidth += fixedHeaders[i].offsetWidth;
        }

        // Then calculate the room each column can have with what is left of the space
        let startingWidth = (this.masterWidth - staticWidth) / this.sizeableColumns.length;

        // Then use the renderer to set the widths, to prevent xss
        for(let i = 0; i < this.sizeableColumns.length; i++) {
            this.renderer.setStyle(this.sizeableColumns[i], 'width', startingWidth+'px');
        }
    }
}

angular.module("comcast.directives").directive(
    'expandingGridHeader',
    downgradeComponent(
        // The inputs and outputs here must match the relevant names of the properties on the
        // "downgraded" component
        {component: ExpandingGridHeaderComponent, inputs: [], outputs: []}));