declare var angular: angular.IAngularStatic;
import { Component, OnDestroy, OnInit } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as _ from 'lodash';    

import { GuidesService, IGuidesRequest, IGuidesResponse } from '../../services/guides.service';
import { LoginService } from "../../authentication/authentication-service";

@Component({
    selector: 'app-guides',
    template: require('./guides.component.html')
})
export class GuidesComponent implements OnInit, OnDestroy {
    userType: string = ''; 
    groupBy: any;
    private _destroy$ = new Subject<void>();
    constructor (private loginService: LoginService, private guidesService: GuidesService) {}
    ngOnInit(): void {
        if (this.loginService.getSessionData().account.adStorFlag) {
            this.userType = 'ADSTOR';
        } else {
            this.userType =
                this.loginService.isAdmin() || !this.loginService.getSessionData().liteAccount
                    ? 'AD'
                    : this.loginService.getSessionData().userType;
        }
        const guidesInputRequest : IGuidesRequest = { userType: this.userType}
        this.guidesService.getGuides(guidesInputRequest).pipe(takeUntil(this._destroy$)).subscribe(res => {
            this.groupBy = _.groupBy(res, 'documentType');
        });
    }
    downloadGuide(guide:IGuidesResponse) {
        this.guidesService.getGuideFile(guide.id).pipe(takeUntil(this._destroy$)).subscribe((doc: any) => {
            var a = document.createElement("a");
            a.href = URL.createObjectURL(doc.data);
            a.download = guide.filename;
            a.click();
        });
    }
    ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }
 }
 angular.module("comcast.directives").directive(
    'appGuides',
    downgradeComponent(
        // The inputs and outputs here must match the relevant names of the properties on the
        // "downgraded" component
        {component: GuidesComponent, inputs: [], outputs: []}));