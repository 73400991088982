// Declare the global values used in this Component
declare var angular: angular.IAngularStatic;

import { Component, Input, SimpleChanges } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import videojs from 'video.js';

@Component({
    selector: 'cts-audio-spot-player',
    template: require('./cts-audio-spot-player.component.html'),
})
export class CtsAudioSpotPlayerComponent {
    @Input() source: string|null = null;
    @Input() type: string|null = null;
    @Input() idx: string|null = null;

    private player: any;

    ngAfterViewInit() {
        let self = this;

        // Initialize the VideoJS player
        videojs('audio_player_' + this.idx,
            {
                techOrder: ['html5'],
                controlBar: {
                    fullscreenToggle: false,
                },
            },
            function (this: any) {
                // Populate the private player value with the newly instantiated instance
                self.player = this;

                // Set sizing
                let playerHeight = 400 * (900 / 1600);
                let playerWidth = 400;

                // Sizing the player (always 16:9)
                self.player.height(playerHeight);
                self.player.width(playerWidth);

                // Position the tracking bar so that it is in the middle of the directive
                let controlBarTop = playerHeight / 2 - 15; // 30 === controlBars fixed height
                let controlBarEle:any = document.getElementsByClassName(
                    'vjs-control-bar'
                )[0];
                controlBarEle.style.top = Math.round(controlBarTop) + 'px';
            });
    }

    ngOnChanges(changes: SimpleChanges) {
        let self = this;

        if(changes['source'].currentValue !== changes['source'].previousValue) {
            self.player.src({
                type: self.type,
                src: changes['source'].currentValue,
            });
        }
    }

    ngOnDestroy() {
        let self = this;

        self.player.dispose();
    }
}

angular.module("comcast.directives").directive(
    'ctsAudioSpotPlayer',
    downgradeComponent(
        // The inputs and outputs here must match the relevant names of the properties on the
        // "downgraded" component
        {component: CtsAudioSpotPlayerComponent, inputs: ['source', 'type', 'idx'], outputs: []}));
